(function () {
  'use strict';

  angular
  .module('neo.home.teams.team')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.teams.team', {
      url: '/{teamId}',
      templateUrl: 'home/teams/team/team.tpl.html',
      controller: 'TeamCtrl',
      controllerAs: 'vm',
      resolve: {
        team: function (Teams, $stateParams) {
          return Teams.get({id: $stateParams.teamId}).$promise
        },
        sportProgramme: function (ProgrammeSports, team) {
          return ProgrammeSports.query({id: team.programmeId}).$promise
        },
        licenseTypes: function (LicenseTypes) {
          return LicenseTypes.query().$promise;
        },
        licenses: function (LicenseTeamDetails, $stateParams) {
          return LicenseTeamDetails.query({teamId: $stateParams.teamId}).$promise;
        }
      }
    });
  }
}());
